

#static_header h2,
#static_header h3 {
    border: 0px;
    max-width: 100%;
    text-align: center;
}

#static_header p {
    text-align: center;
}

#static_header img {
    max-width: 100%;
}

#static_header .button {
    margin: 0rem 0rem 2rem 0rem;
    font-weight: 700;
    box-shadow: none;
    border: 2px solid white;
}