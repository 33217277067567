
.xp-bar-box {
    display:flex;
    justify-content: space-around;
}

.xp-bar {
    width:75%
}

