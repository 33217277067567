
.day-container {
    max-width: 100%;
}

#main article.day {
    padding: 1.5rem 2.5rem 1.5rem 2.5rem;
    width:75%;
}

#main article h2.date {
    margin: 0 0 0 0;
    text-align: center;
}


.drop-down {
    display:flex;
    flex-direction:row;
    justify-content: space-between;
    align-items: center;
}

#arrow {
    width: 2.75rem;
}
.calendar-button-edit {
    border: 2px solid white;
    border-radius: 1rem;
    padding: .5rem 1rem .5rem 1rem;
}
.task-container {
    background-color:white;
    color:black;
    width:95%;
    border-bottom-left-radius:4px ;
    border-bottom-right-radius:4px;
    padding: 1rem 2rem 1rem 2rem;
    width:73%;
}
.evidence-file-url:link {
    color: #0000EE;
    text-decoration: underline;
}
.evidence-file-url:visited {
    color: #551A8B;
    text-decoration: underline;
}
#log-root {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width:100%;
}
.day-container {
    width:100%;
}
.flex-column {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}
.flex-row {
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width:100%;
}

#log-root .task-container p {
    color:black;
    margin: 0px;
    padding: .5rem 0 .5rem 0;
}

#log-root .task-container h2,#log-root .task-container h3  {
    color:black;
    margin: 0px;
}
#log-root .task-container h2 {
    padding-top:2rem;
    padding-bottom:1rem;
}

@media screen and (max-width: 480px) {
    .task-container {
        width:95%;
    }
    #main article.day { 
        width:100%;
    }
    .flex-row {
        flex-direction: column;
    }
}

.task {
    padding: 2rem 1rem 2rem 1rem;
}

.evidence-header {
    color:black;
    margin:.5rem 0 0 0;
}