#main {
    width:100%;
}

.achievement {
    display:flex;
    flex-direction: row;
    padding: 1.5rem 1.5rem .5rem 1.5rem;
    border-radius:1rem;
    width:75%;
}
.achievement-text h3 {
    text-align: center;
    max-width: 100%;
    margin: 0 0 0 0;

}   
.achievement-text {
    margin:0 0rem 0 1rem;
}
#main article.achievement {
    width: 75%;
    word-wrap:break-word;
}
@media screen and (max-width: 480px) {

    #main article.achievement {
        width: 100%;
        flex-direction: column;
        word-wrap:break-word;
    }
    .achievement-text h3 {
        text-align: center;
        max-width: 100%;
        margin-top:.5rem;
    }  
    .achievement-text {
        margin: 0px;
        text-align: center;
    }

}


.achievement-selector {
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.achievement-imgs {

    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

}
.achievement-selector select {
    font-size: 1.12rem;
    font-weight: 600;
}
.achievements-container {
    width:100%;
    display:flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
}
.locked-achievement {
    position:absolute;
    z-index:4;
    width:4rem;
    height:auto;
}

.achievement-img {
    border-radius:50%;
    height:7rem;
    width:7rem;
    position: relative;
    z-index:2;
    border: 3px solid white;
}

.achievement-text {
    width:100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height:auto
}